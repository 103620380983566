@import 'common/normalize';

:root{
	--white-main: #131313;
	--white-sub: #9A9A9A;
	--prpl-btn: #6E1867;
	--prpl-btn-hover: #8A2881;
	--prpl-btn-active: #480842;
	--prpl-light: #6F4FC9;
	--black-btn: #373A40; 
	--background: #ffffff;
	--background-light: #2D2F34;
}

/*::selection {
	background-color: rgba(88,53,186,0.99);
	color: var(--white-main);
}*/

@media screen and (min-width: 1200px){
	::-webkit-scrollbar {
			width: 17px;
	}
	::-webkit-scrollbar-button {
		display: none;
	}
	::-webkit-scrollbar-thumb {
		background-color: var(--prpl-btn);
		border-radius: 5px;
		border: 2px solid var(--background);
	}
	::-webkit-scrollbar-track {
		background-color: var(--background);
	}
}

html,
body{
	display: block;
	color: var(--white-main);
	margin: 0;
	font-size: 16px;
	line-height: 150%;
	font-family: 'Bw Quinta Pro';
	letter-spacing: 0.0075em;
	font-feature-settings: 'pnum' on, 'lnum' on;
}
.wrapper {
  display: flex;
  min-height: 100vh;
	flex-direction: column;
	overflow-x: hidden;
	background: var(--background);
	
}
.content {
	flex: 1;
	position: relative;
}
.mycontainer{
	max-width: 1360px;
	padding: 0 40px;
	margin: 0 auto;
}
.title{
	font-size: 36px;
	line-height: 135%;
	font-weight: bold;
	span{
		margin-left: 8px;
		color: var(--prpl-btn);
	}
}


@import 'common/adaptive';
@import 'common/fonts';
@import 'common/header';
@import 'common/footer';
@import 'common/popup';
@import 'pages/index';


body{
	&.fixed{
		overflow: hidden;
		@include hover{
			padding-right: 17px;
			.popup{
				padding-right: 17px;
				&__close{
					right: 57px;
				}
			}
		}
	}
}

@include w1024{
	.title{
		font-size: 30px;
	}
}

@include w480{
	.title{
		font-size: 24px;
	}
	.mycontainer{
		padding: 0 16px;
	}
}