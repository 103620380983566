.header{
	padding: 10px 0;
	.mycontainer{
		display: flex;
		align-items: center;
	}
	&-menu{
		display: flex;
		align-items: center;
		margin-left: auto;
		position: relative;
		&:after{
			content: '';
			width: 1px;
			height: 20px;
			background: var(--white-sub);
			margin-right: 40px;
			margin-left: 5px;
		}
		&__href{
			color: var(--white-sub);
			padding: 5px;
			margin-right: 40px;
			transition: .3s;
			@include hover{
				&:hover{
					color: var(--white-main);
				}
			}
		}
	}
	&-info{
		display: flex;
		align-items: center;
		&__tel{
			font-family: 'Avenir Next Cyr';
			margin-right: 25px;
		}
		&__btn{
			width: 200px;
			height: 50px;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 2px solid var(--prpl-btn);
			border-radius: 3px;
			transition: .3s;
			@include hover{
				&:hover{
					background: var(--prpl-btn);
					color: #F9F9F9
				}
				&:active{
					border: 2px solid var(--prpl-btn-active);
					background: var(--prpl-btn-active);
					transition: 0s;
				}
			}
		}
		&__burger{
			display: none;
		}
	}
	&-popup{
		display: none;
	}
}

@include w1024{
	.header{
		&-menu{
			&:after{
				margin-right: 20px;
			}
			&__href{
				margin-right: 20px;
			}
		}
		&-info{
			&__btn{
				width: 180px;
				height: 45px;
			}
		}
	}
}

@include w768{
	.header{
		position: relative;
		.mycontainer{
			position: relative;
			z-index: 10;
		}
		&-menu{
			display: none;
		}
		&-info{
			margin-left: auto;
			&__burger{
				display: flex;
				padding: 20px;
				margin-right: -20px;
				margin-left: 20px;
				cursor: pointer;
				&.active{
					svg{
						line{
							&:nth-child(1){
								transform: translate(5px, -2px) rotate(45deg);
							}
							&:nth-child(2){
								transform: translateX(100%);
								opacity: 0;
							}
							&:nth-child(3){
								transform: translate(-7px, 8px) rotate(-45deg);
							}
						}
					}
				}
				svg{
					overflow: visible;
					line{
						transition: .3s;
					}
				}
			}
		}
		&-popup{
			display: block;
			position: fixed;
			z-index: 3;
			width: 100%;
			height: 100vh;
			height: calc(var(--vh, 1vh) * 100);
			left: 0;
			top: 0;
			background: #fff;
			padding: 0 40px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			transform: translateY(-100%);
			transition: .5s;
			&.active{
				transform: translateY(0);
				.header-popup-menu{
					transition: .3s;
					transition-delay: .3s;
					opacity: 1;
				}
			}
			&-menu{
				transition: 0s;
				opacity: 0;
				&__item{
					font-size: 36px;
					padding: 20px 0;
					&:last-child{
						margin-bottom: 0;
					}
				}
			}
			&-bot{
				display: none;
			}
		}
	}
}

@include w480{
	.header{
		padding: 0;
		&-info{
			&__tel,
			&__btn{
				display: none;
			}
		}
		&-popup{
			padding: 80px 16px 0;
			&.active{
				.header-popup-bot{
					transition: .3s;
					transition-delay: .3s;
					opacity: 1;
				}
			}
			&-menu{
				margin: auto 0;
				&__item{
					font-size: 24px;
					padding: 16px 0;
				}
			}
			&-bot{
				display: block;
				margin-top: auto;
				border-top: 1px solid var(--prpl-btn);
				margin: 0 -16px;
				padding: 24px 16px;
				transition: 0s;
				opacity: 0;
				&__tel{
					font-family: 'Avenir Next Cyr';
					text-align: center;
					margin-bottom: 16px;
				}
				&__btn{
					width: 100%;
					height: 40px;
					border: 2px solid var(--prpl-btn);
					border-radius: 3px;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}
	}
}
