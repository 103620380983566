.footer{
	background: linear-gradient(270deg, rgba(243, 234, 255, 0.25) 0%, rgba(252, 249, 255, 0.25) 50.3%, rgba(253, 249, 255, 0.25) 100.13%, rgba(255, 255, 255, 0.25) 119.81%);
	padding: 40px 0;
	font-size: 18px;
	line-height: 150%;
	height: 400px;
	.mycontainer{
		display: flex;
		justify-content: space-between;
		height: 100%;
	}
	&-col{
		display: flex;
		flex-direction: column;
		height: 100%;
		&:last-child{
			margin-right: 8%;
		}
		&__logo{
			margin-bottom: 30px;
		}
		&__title{
			margin-bottom: 16px;
		}
		&__text{
			color: var(--white-sub); 
			margin-bottom: 44px;
			max-width: 366px;
		}
		&-row{
			display: flex;
			a{
				margin-right: 36px;
			}
		}
		a{
			color: var(--white-sub); 
			margin-bottom: 12px;
			transition: .3s;
			@include hover{
				&:hover{
					color: var(--white-main);
				}
			}
			&.footer-col__last{
				margin-bottom: 0;
			}
		}
		&__last{
			margin-top: auto;
			color: var(--white-sub); 
			br{
				display: none;
			}
		}
	}
}

@include w1024{
	.footer{
		position: relative;
		height: auto;
		margin-bottom: 40px;
		.mycontainer{
			flex-direction: column;
		}
		&-col{
			margin-bottom: 40px;
			flex-direction: row;
			flex-wrap: wrap;
			a{
				margin-bottom: 0;
			}
			&:nth-child(2){
				.footer-col__text + .footer-col__title{
					order: -2;
				}
				.footer-col-row{
					order: -1;
					margin-bottom: 40px;
				}
				.footer-col__last{
					right: 0;
				}
			}
			&:nth-child(3){
				margin-right: 0;
				margin-bottom: 60px;
				.footer-col__last{
					bottom: 30px;
					right: 0;
				}
			}
			&__logo{
				margin-bottom: 15px;
				width: 100%;
			}
			&__title{
				width: 100%;
			}
			&__text{
				max-width: none;
				margin-bottom: 0;
			}
			&__last{
				margin-top: 0;
				position: absolute;
				bottom: 0;
				br{
					display: block;
				}
			}
			a{
				margin-right: 40px;
			}
		}
	}
}

@include w480{
	.footer{
		padding-bottom: 140px;
		font-size: 17px;
		&-col{
			&__last{
				margin-bottom: 105px;
			}
			&:nth-child(2){
				.footer-col__last{
					left: 16px;
					right: auto;
				}
			} 
			&:nth-child(3){
				.footer-col__last{
					left: 16px;
					right: auto;
				}
			} 
		}
	}
}