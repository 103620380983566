.main{
	&-banner{
		margin-top: 80px;
		margin-bottom: 100px;
		.mycontainer{
			display: flex;
		}
		&-col{
			&:first-child{
				margin-top: 70px;
				flex: 0 0 530px;
				position: relative;
				z-index: 2;
			}
			&:last-child{
				margin-right: -30px;
				transform: translateX(-100px);
				img{
					transform: translateX(-30px);
				}
			}
			&__title{
				font-weight: bold;
				font-size: 48px;
				line-height: 135%;
				margin-bottom: 30px;
			}
			&__text{
				font-size: 18px;
				line-height: 135%;
				color: var(--white-sub);
				margin-bottom: 120px;
			}
			&-bot{
				display: flex;
				align-items: center;
				&__btn{
					width: 253px;
					height: 60px;
					display: flex;
					justify-content: center;
					align-items: center;
					background: var(--prpl-btn);
					box-shadow: 1px 2px 10px rgba(183, 104, 176, 0.1);
					border-radius: 3px;
					margin-right: 40px;
					transition: .3s;
					color: #F9F9F9;
					@include hover{
						&:hover{
							background: var(--prpl-btn-hover);
							box-shadow: 1px 2px 10px rgba(183, 104, 176, 0.3);
							
						}
						&:active{
							background: var(--prpl-btn-active);
							transition: 0s;
							box-shadow: 1px 2px 10px rgba(183, 104, 176, 0.1);
						}
					}
				}
				&__text{
					color: #363636;
					padding: 5px;
					transition: .3s;
					font-size: 14px;
					span{
						font-family: 'Avenir Next Cyr';
						color: #431F6E;
						font-size: 24px;
						span{
							font-family: 'Roboto', sans-serif;
							font-weight: bold;
						}
					}
					@include hover{
						&:hover{
							color: var(--white-main);
						}
					}
				}
			}
			p{
				margin-top: 80px;
				color: rgba(154, 154, 154, 0.5);
				font-size: 14px;
			}
		}
	}
	&-include{
		margin-bottom: 80px;
		.title{
			display: flex;
			align-items: center;
			margin-bottom: 80px;
			span{
				background: #93203F;
				border-radius: 4px;
				font-size: 18px;
				font-weight: 500;
				color: #FFFFFF;
				height: 35px;
				width: 180px;
				display: flex;
				align-items: center;
				justify-content: center;
				margin-left: 24px;
			}
		}
		&-content{
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: 80px;
			&-item{
				&__img{
					margin-bottom: 24px;
					position: relative;
					svg{
						position: absolute;
						right: 0;
						top: 50%;
						transform: translateY(-50%);
					}
				}
				&__title{
					font-size: 20px;
					margin-bottom: 16px;
				}
				&__text{
					font-size: 18px;
					color: var(--white-sub)
				}
			}
		}
	}
	&-row{
		margin-bottom: 160px;
		padding: 24px;
		background: linear-gradient(270deg, #E3E3FF -142.46%, #E8E8FF -116.45%, #F9F9FF 7.8%, #FCFAFF 99.47%);
		display: flex;
		justify-content: space-between;
		align-items: center;
		&__title{
			font-size: 20px;
		}
		&-right{
			display: flex;
			align-items: center;
			span{
				font-family: 'Avenir Next Cyr';
				font-size: 24px;
				color: #431F6E;
				span{
					font-family: 'Roboto', sans-serif;
					font-weight: bold;
				}
			}
			&__btn{
				width: 200px;
				height: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				background: var(--prpl-btn);
				box-shadow: 1px 2px 10px rgba(183, 104, 176, 0.1);
				border-radius: 3px;
				transition: .3s;
				color: #F9F9F9;
				font-size: 18px;
				margin-left: 40px;
				@include hover{
					&:hover{
						background: var(--prpl-btn-hover);
						box-shadow: 1px 2px 10px rgba(183, 104, 176, 0.3);
						
					}
					&:active{
						background: var(--prpl-btn-active);
						transition: 0s;
						box-shadow: 1px 2px 10px rgba(183, 104, 176, 0.1);
					}
				}
			}
		}
	}
	&-faq{
		margin-bottom: 160px;
		.title{
			margin-bottom: 80px;
			text-align: center;
		}
		&-item{
			border-top: 1px solid #383A40;
			&:last-child{
				border-bottom: 1px solid #383A40;
			}
			&__title{
				font-size: 20px;
				padding: 40px 0;
				cursor: pointer;
				position: relative;
				@include hover{
					&:hover{
						span{
							background: rgba(110, 24, 103, 0.25);
						}
					}
				}
				&.active{
					span:after{
						transform: translate(10px, 20px) rotate(0deg);
					}
				}
				span{
					position: absolute;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					width: 40px;
					height: 40px;
					border-radius: 50%;
					transition: .3s;
					&:before,
					&:after{
						position: absolute;
						content: '';
						width: 20px;
						height: 1.5px;
						background: var(--white-main);
						transition: .3s;
					}
					&:before{
						transform: translate(10px, 20px);
					}
					&:after{
						transform: translate(10px, 20px) rotate(90deg);
					}
				}
			}
			&__text{
				font-size: 18px;
				color: var(--white-sub);
				margin-bottom: 40px;
				margin-top: -24px;
				display: none;
			}
		}
	}
	&-cases{
		margin-bottom: 160px;
		.title{
			margin-bottom: 40px;
		}
		&-content{
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 40px;
			&-item{
				&__img{
					margin-bottom: 16px;
					box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
					img{
						width: 100%;
					}
				}
				&__title{
					font-size: 20px;
					margin-bottom: 8px;
					font-weight: bold;
				}
				&__text{
					color: #333333;
				}
			}
		}
	}
	&-about{
		margin-bottom: 160px;
		&-top{
			margin-bottom: 40px;
			display: flex;
			justify-content: space-between;
			&-col{
				flex: 0 0 25%;
				&:first-child{
					flex: 0 0 38%;
				}
				&:last-child{
					flex: 0 0 12%
				}
				&__title{
					font-weight: bold;
					font-size: 36px;
					line-height: 135%;
					color: #6E1867;
				}
				span{
					display: block;
					color: var(--white-sub);
					font-size: 18px;
					margin-top: 6px;
				}
			}
		}
		&__img{
			img{
				width: 100%;
			}
		}
		&-bot{
			display: flex;
			justify-content: space-between;
			margin-top: 40px;
			padding-right: 30px;
			p{
				color: var(--white-sub);
				font-size: 18px;
				flex: 0 0 49%;
			}
			&-adv{
				flex: 0 0 15%;
				text-align: center;
				position: relative;
				&:before,
				&:after{
					content: '';
					position: absolute;
					top: -5px;
					width: 26px;
					height: 80px;
					background-size: contain;
					background-repeat: no-repeat;
					background-position: center;
				}
				&:before{
					left: -30px;
					background-image: url('../images/lavr-left.svg');
				}
				&:after{
					right: -30px;
					background-image: url('../images/lavr-right.svg');
				}
				&__title{
					font-size: 18px;
					margin-bottom: 8px;
				}
				&__text{
					font-size: 14px;
					color: var(--white-sub)
				}
			}
		}
	}
	&-form{
		margin-bottom: 160px;
		&-wrap{
			background: linear-gradient(270deg, #E3E3FF -142.46%, #E8E8FF -116.45%, #F9F9FF 7.8%, #FCFAFF 99.47%);
			padding: 40px;
			.title{
				margin-bottom: 40px;
			}
			&-content{
				display: flex;
				justify-content: space-between;
				&-col{
					&:first-child{
						flex: 0 0 45%
					}
					p{
						font-size: 20px;
						line-height: 150%;
						+ p{
							margin-top: 80px;
						}	
					}
					ul{
						font-size: 20px;
						line-height: 150%;
						margin-bottom: 80px;
					}
					&-bot{
						border: 1px solid #6E1867;
						border-radius: 4px;
						padding: 24px 64px;
						font-size: 20px;
						max-width: 480px;
						span{
							font-family: 'Avenir Next Cyr';
							font-weight: bold;
							font-size: 36px;
							margin-right: 5px;
							span{
								font-family: 'Roboto', sans-serif;
							}
						}
					}
				}
				.general-form{
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;
					max-width: 480px;
					align-items: flex-start;
					align-self: flex-start;
					.general-itemInput{
						position: relative;
						box-sizing: border-box;
						margin-bottom: 56px;
						width: 100%;
						&.general-itemInput_25{
							width: calc(100% / 4 - (90px / 4));
						}
						&.general-itemInput_33{
							width: calc(100% / 3 - (60px / 3));
						}
						&.general-itemInput_50{
							width: calc(100% / 2 - (30px / 2));
						}
						&__input{
							border: none;
							border-bottom: 1px solid #9A9A9A;
							height: 100%;
							width: 100%;
							background: none;
							font-size: 18px;
							height: 38px;
							&::placeholder{
								color: #9A9A9A;
							}
							&:focus{
								border-bottom: 1px solid #000;
							}
							&.input-border{
								border-bottom: 1px solid #000;
							}
							&.input-err{
								border-bottom: 1px solid red;
							}
						}
					}
					.general-bottomBlock{
						display: flex;
						align-items: center;
						margin-top: 24px;
						button{
							width: 230px;
							height: 62px;
							display: flex;
							justify-content: center;
							align-items: center;
							background: var(--prpl-btn);
							border-radius: 3px;
							color: #FFFFFF;
							font-weight: 600;
							letter-spacing: 0.75px;
							border: none;
							margin-right: 20px;
							cursor: pointer;
							transition: .3s;
							@include hover{
								&:hover{
									background: var(--prpl-btn-hover);
									box-shadow: 1px 2px 10px rgba(183, 104, 176, 0.3);
									
								}
								&:active{
									background: var(--prpl-btn-active);
									transition: 0s;
									box-shadow: 1px 2px 10px rgba(183, 104, 176, 0.1);
								}
							}
						}
						p{
							color: var(--white-sub);
							max-width: 350px;
							font-size: 14px;
							a{
								display: inline-block;
								margin-left: 3px;
								text-decoration: underline;
								color: var(--prpl-btn);
							}
						}
					}
				}
			}
		}
	}
}

@include w1024{
	.main{
		&-banner{
			.mycontainer{
				position: relative;
			}
			&-col{
				&:first-child{
					margin-top: 0;
					flex: 0 0 400px;
					padding-bottom: 70px;
					position: static;
				}
				&:last-child{
					img{
						width: 760px;
						transform: translateX(-70px);
					}
				}
				&__title{
					font-size: 36px;
				}
				&__text{
					font-size: 16px;
					max-width: 280px;
					margin-bottom: 70px;
				}
				&-bot{
					flex-direction: column-reverse;
					align-items: flex-start;
					&__btn{
						width: 210px;
						height: 50px;
					}
					&__text{
						margin-bottom: 16px;
					}
				}
				p{
					margin-top: 0;
					position: absolute;
					bottom: 0;
					left: 40px;
				}
			}
		}
		&-include{
			.title{
				span{
					margin-left: auto;
				}
			}
			&-content{
				&-item{
					&__title{

					}
					&__text{
						br{
							display: none;
						}
					}
				}
			}
		}
		&-row{
			margin-bottom: 120px;
			&__title{
				max-width: 370px;
			}
			&-right{
				span{
					display: none;
				}
				&__btn{
					width: 190px;
					height: 45px;
					font-size: 16px;
				}
			}
		}
		&-cases{
			margin-bottom: 120px;
		}
		&-about{
			margin-bottom: 120px;
		}
		&-form{
			margin-bottom: 120px;
			&-wrap{
				padding: 40px 24px;
				&-content{
					&-col{
						&:first-child{
							flex: 0 0 42%;
						}
						p{
							font-size: 16px;
							line-height: 135%;
							+ p{
								margin-top: 64px;
							}
							b{
								margin-left: 5px;
							}
							br{
								display: none;
							}
						}
						ul{
							font-size: 16px;
							margin-bottom: 64px;
						}
						&-bot{
							padding: 24px 40px;
							font-size: 16px;
							text-align: center;
							span{
								font-size: 24px;
							}
						}
					}
					.general-form{
						max-width: 50%;
						.general-itemInput{
							margin-bottom: 32px;
							&__input{
								font-size: 16px;
    						height: 36px;
							}
						}
						.general-bottomBlock{
							margin-top: 8px;
							flex-direction: column;
							align-items: flex-start;
							button{
								height: 50px;
								margin-bottom: 20px;
							}
						}
					}
				}
			}
		}
		&-faq{
			margin-bottom: 120px;
			.title{
				margin-bottom: 48px;
			}
			&-item{
				&__title{
					padding: 32px 0;
				}
				&__text{
					font-size: 16px;
					margin-bottom: 32px;
					margin-top: -16px;
				}
			}
		}
	}
}

@include w768{
	.main{
		&-banner{
			&-col{
				&:last-child{
					img{
						width: 550px;
						transform: translate(-150px, 150px);
					}
				}
				&__text{
					margin-bottom: 170px;
				}
			}
		}
		&-include{
			.title{
				span{
					font-size: 16px;
					height: 32px;
					width: 166px;
				}
			}
			&-content{
				grid-template-columns: repeat(2, 1fr);
				grid-gap: 40px 80px;
				&-item{
					&__img{
						svg{
							display: none;
						}
					} 
				}
			}
		}
		&-cases{
			&-content{
				grid-template-columns: repeat(1, 1fr);
			}
		}
		&-about{
			&-top{
				&-col{
					&__title{
						font-size: 30px;
					}
					span{
						font-size: 16px;
					}
				}
			}
			&-bot{
				flex-wrap: wrap;
				justify-content: center;
				padding-right: 0;
				p{
					flex: 0 0 100%;
					font-size: 16px;
					margin-bottom: 40px;
				}
				&-adv{
					flex: 0 0 30%;
					margin: 0 65px;
				}
			}
		}
		&-form{
			&-wrap{
				&-content{
					&-col{
						&:first-child{
							flex: 0 0 47%;
						}
						&-bot{
							padding: 24px 32px;
						}
					}
					.general-form {
						max-width: 45%;
					}
				}
			}
		}
	}
}

@include w480{
	.main{
		&-banner{
			margin-top: 40px;
			&-col{
				&:first-child{
					flex: 0 0 100%
				}
				&:last-child{
					transform: none;
					margin-right: 0;
					img{
						transform: none;
						position: absolute;
						left: 0px;
						top: 170px;
						width: 100%;
					}
				}
				&__title{
					font-size: 24px;
					margin-bottom: 16px;
				}	
				&__text{
					margin-bottom: 270px;
				}
				p{
					left: 16px;
					width: calc(100% - 32px);
					text-align: center;
					font-size: 13px;
				}
				&-bot{
					align-items: center;
					&__text{
						padding: 0;
					}
					&__btn{
						width: 100%;
						margin-right: 0;
					}
				}
			}
		}
		&-include{
			margin-bottom: 64px;
			.title{
				flex-direction: column;
				align-items: flex-start;
				margin-bottom: 40px;
				span{
					margin-left: 0;
					margin-top: 8px;
				}
			}
			&-content{
				grid-template-columns: repeat(1, 1fr);
			}
		}
		&-row{
			margin-bottom: 64px;
			padding: 16px;
			flex-direction: column;
			align-items: flex-start;
			&__title{
				font-size: 16px;
				margin-bottom: 16px;
			}
			&-right{
				width: 100%;
				&__btn{
					margin-left: 0;
					width: 100%;
				}
			}
		}
		&-about{
			margin-bottom: 80px;
			&-top{
				flex-wrap: wrap;
				&-col{
					&:first-child{
						flex: 0 0 100%;
						margin-bottom: 40px;
					}
					span{
						white-space: nowrap;
					}
				}
			}
			&__img{
				height: 230px;
				img{
					height: 100%;
					width: 100%;
					object-fit: cover;
				}
			}
			&-bot{
				&-adv{
					flex: 0 0 80%;
					margin: 0;
					margin-bottom: 40px;
				}
			}
		}
		&-form{
			&-wrap{
				margin: 0 -16px;
				padding: 40px 16px;
				&-content{
					flex-direction: column;	
					&-col{
						p{
							+p{
								margin-top: 40px;
							}
						}
						ul{
							margin-bottom: 40px;
						}
						&-bot{
							margin-bottom: 32px;
							padding: 24px;
						}
					}
					.general-form{
						max-width: none;
						.general-bottomBlock{
							&__text,
							button{
								width: 100%;
								text-align: center;
							}
						}
					}
				}
			}
		}
		&-faq{
			.title{
				margin-bottom: 40px;
			}
			&-item{
				&__title{
					font-size: 18px;
					padding-right: 30px;
					span{
						top: 23px;
						transform: none;
						&:before,
						&:after{
							height: 1px;
						}
					}
				}
			}
		}
	}
}